/*
 * @Description: 
 * @Author: hecai
 * @Date: 2022-11-24 18:37:37
 * @LastEditTime: 2023-03-02 17:56:57
 * @FilePath: \hands\src\Component\CameraComponent.jsx
 */

import React, { useRef, useEffect, useState } from 'react';
import * as handPoseDetection from '@tensorflow-models/hand-pose-detection';
import '@tensorflow/tfjs-core';
// Register WebGL backend.
import '@tensorflow/tfjs-backend-webgl';
import '@mediapipe/hands';
// import '@tensorflow/tfjs-core';
// // Register WebGL backend.
// import '@tensorflow/tfjs-backend-webgl';
// import '@mediapipe/pose';

const CameraComponent = (props) => {
  var ratio = 1;
  var offsetX = 0;
  var offsetY = 0;
  const { draw } = props
  const drawRef=useRef(true)
  const cameraVideoRef = useRef(null);
  const cameraCanvasRef = useRef(null);
  const initModleRef = useRef(false);
  const detector = useRef(null);
  const LineList = [
    [0,1],
    [1,2],
    [2,3],
    [3,4],
    [0,5],
    [5,6],
    [6,7],
    [7,8],
    [5,9],
    [9,10],
    [10,11],
    [11,12],
    [9, 13],
    [13, 14],
    [14, 15],
    [15,16 ],
    [13,17 ],
    [17, 18],
    [18, 19],
    [19, 20],
    [17,0 ]
    
  ]
  async function initModle() {
    console.log('initModle');
    const model = handPoseDetection.SupportedModels.MediaPipeHands;
    const detectorConfig = {
      runtime: 'mediapipe',
      solutionPath: 'https://cdn.jsdelivr.net/npm/@mediapipe/hands',
      // solutionPath: '@mediapipe/pose',
      modelType: 'full'
    };
    detector.current = await handPoseDetection.createDetector(model, detectorConfig);
    console.log('initModle finish');
    openMedia();
  }
  function drawDot(ctx, x, y, dotRadius, dotColor) {
    ctx.beginPath();
    x = x * ratio + offsetX
    y = y * ratio + offsetY
    //x=props.width-x
    ctx.arc(x, y, dotRadius, 0, 2 * Math.PI, false);
    ctx.fillStyle = dotColor;
    ctx.fill();
  }
  // function drawRect(ctx, x, y, width, height)
  // {
  //   ctx.strokeStyle="#0000ff";
  //   ctx.strokeRect(x, y, width, height);
  // }
  function drawLine(ctx, x, y, x1, y1, width, lineColor) {
    x = x * ratio + offsetX
    y = y * ratio + offsetY
    x1 = x1 * ratio + offsetX
    y1 = y1 * ratio + offsetY
    //x = props.width - x
    //x1 = props.width -x1
    ctx.beginPath();
    ctx.moveTo(x, y);
    ctx.lineTo(x1, y1);
    ctx.closePath();
    ctx.lineWidth = width;
    ctx.strokeStyle = lineColor;
    ctx.stroke();
  }
  function drawPose(pose) {
    const ctx = cameraCanvasRef.current.getContext('2d');
    ctx.clearRect(0, 0, cameraCanvasRef.current.width, cameraCanvasRef.current.height);
    

    if (pose.length > 0) {
      if (drawRef.current) {
        for (let h = 0; h < pose.length; h++) {
          for (const v of pose[h].keypoints) {
            drawDot(ctx, v.x, v.y, 3, "red");
          }
          for (let i = 0; i < LineList.length; i++) {
            let v0 = pose[h].keypoints[LineList[i][0]]
            let v1 = pose[h].keypoints[LineList[i][1]]
            drawLine(ctx, v0.x, v0.y, v1.x, v1.y, 3, 'green')
          }
        }
      }
      props.getPoseFun(pose)
    } 
    else
      props.getPoseFun(null)
  }


  async function getPose() {
    if (detector.current) {
      const estimationConfig = { flipHorizontal: false };
      if ((cameraVideoRef.current.videoWidth !== props.width || cameraVideoRef.current.videoHeight !== props.height)
      &&ratio===1 && offsetX===0 && offsetY===0) {
        if (props.width / props.height > cameraVideoRef.current.videoWidth / cameraVideoRef.current.videoHeight) {
          ratio = props.height / cameraVideoRef.current.videoHeight
          offsetX =(props.width -props.height* cameraVideoRef.current.videoWidth / cameraVideoRef.current.videoHeight)/2
        } else {
          ratio = props.width / cameraVideoRef.current.videoWidth
          offsetY = (props.height - props.width * cameraVideoRef.current.videoHeight/cameraVideoRef.current.videoWidth)/2
        }
      }
      const pose = await detector.current.estimateHands(cameraVideoRef.current, estimationConfig);
      drawPose(pose);
    }
  }
  function successFunc(mediaStream) {
    const video = cameraVideoRef.current;
    // const video = document.getElementById('cameraVideo') as HTMLVideoElement;
    // 旧的浏览器可能没有srcObject
    if ('srcObject' in video) {
      video.srcObject = mediaStream;
    }
    console.log('open Camera success');
    video.onloadedmetadata = () => {
      video.play();
      window.setInterval(() => {
        getPose();
      }, 50);
    };
  }
  useEffect(() => {
    drawRef.current=draw
  }, [draw]); // 👈️ 添加要跟踪的状态变量

  function errorFunc(err) {
    console.log(`${err.name}: ${err.message}`);
    // always check for errors at the end.
  }
  // 启动摄像头
  const openMedia = () => { // 打开摄像头
    console.log("open Camera");
    const opt = {
      audio: false,
      video: {
        width: props.width,
        height: props.height
      }
    };
    navigator.mediaDevices.getUserMedia(opt).then(successFunc).catch(errorFunc);
  };
  // 关闭摄像头
  // const closeMedia = () => {
  //   // const video = document.getElementById('cameraVideo') as HTMLVideoElement;
  //   const video = cameraVideoRef.current;
  //   const stream = video.srcObject;
  //   if ('getTracks' in stream) {
  //     const tracks = stream.getTracks();
  //     tracks.forEach(track => {
  //       track.stop();
  //     });
  //   }
  // };

  useEffect(() => {
    // 相当于 componentDidMount
    if (!initModleRef.current) {
      initModle();
      initModleRef.current = true;
    }
    return () => {
      //closeMedia();
      // 相当于 componentWillUnmount
    }
  }, []);

  return (
    <div style={{
      position: 'absolute',
      top: 0,
      width: props.width + 'px',
      height: props.height + 'px',
      left: '50%',
      transform: 'translate(-50%,0)',
      zIndex: 98,
    }}>
      <video playsinline
        id="cameraVideo"
        ref={cameraVideoRef}
        style={{
          width: props.width + 'px', height: props.height + 'px',
          transform: 'scaleX(-1)',
          //transform: 'rotateY(180deg)',
          display: 'block',
          position: 'absolute',
          top: 0,
          left: 0,
          opacity: props.opacity
        }}
      />
      <canvas
        id="cameraCanvas"
        ref={cameraCanvasRef}
        width={props.width}
        height={props.height}
        style={{
          width: props.width+'px', height: props.height+'px',
          position: 'absolute',
          transform: 'scaleX(-1)',
          top: 0,
          left: 0,
          opacity: props.opacity
        }}
      />
      <div style={{
        width: props.width+'px', height: props.height+'px',
        position: 'absolute',
        top: 0,
        left: 0,
        opacity: props.opacity?0.5:0
      }}>
        </div>
      
      
    </div>
  )
}

export default CameraComponent;
