import React, { useState, useCallback, useRef } from "react";
import CameraComponent from '../Component/CameraComponent';
import '../Component/buttons/buttons.css';
import html2canvas from 'html2canvas'
import QRCode from 'qrcode.react'
import Axios from 'axios'
import Utils from "../Component/utils";

const Home = () => {
  const vWidth = document.querySelector('body').offsetWidth;
  const vHeight = document.querySelector('body').offsetHeight;
  console.log(vWidth, vHeight);
  const [background, showBackground] = useState(false)
  const [picUrl, setUrl] = useState('')
  const [picBase64,setBase64] =useState('')
  const pushAmount = useRef(0)
  const picRef=useRef(null)
  const homeRef = useRef(null)
  const countDownRef=useRef(0)
  const [countDown, setCountDown] = useState(0)
  const countPoseRef=useRef(0)
  const [countPose,setCountPose]=useState(0)
  const timer = useRef(null)
  const timerCheckPose =useRef(null)
  const poseRef = useRef(null)
  const [draw,setDraw] =useState(true)
  //计算AB，AC 的夹角A的角度
  function angle(A, B, C) {
    if (A.score > 0.8 && B.score > 0.8 && C.score > 0.8) {

      let AB = Math.sqrt(Math.pow(A.x - B.x, 2) + Math.pow(A.y - B.y, 2));
      let AC = Math.sqrt(Math.pow(A.x - C.x, 2) + Math.pow(A.y - C.y, 2));
      let BC = Math.sqrt(Math.pow(B.x - C.x, 2) + Math.pow(B.y - C.y, 2));
      let cosA = (
        Math.pow(AB, 2) + Math.pow(AC, 2) - Math.pow(BC, 2)
      ) / (
          2 * AB * AC
        );
      let angleA = Math.round(Math.acos(cosA) * 180 / Math.PI);
      return angleA
    } else
      return -1
  }

  function getLen(pose,p1, p2)
  {
    return Math.sqrt(Math.pow(pose[p1].x - pose[p2].x, 2) + Math.pow(pose[p1].y - pose[p2].y, 2));
  }

  function checkPose() {
    //console.log(pose)
    for (let i = 0; i < poseRef.current.length; i++)
    {
      let pose=poseRef.current[i].keypoints
      let lenTest1=getLen(pose, 0, 5) *0.6
      let lenTest2=getLen(pose, 5, 9)
      let lenTest3=getLen(pose, 8, 12)
      let lenF1 = getLen(pose, 5, 8)
      let lenF2 = getLen(pose, 9, 12)
      console.log(lenTest1,lenTest2,lenTest3,lenF1,lenF2)
      if (lenF1 > lenTest1 && lenF2 > lenTest1 && lenTest3 > lenTest2 )
        return true
    }
    return false
  }

  //获取canvas画布相应区域的图像信息，并返回其base64格式的图像

  function getBase64Image(context, x1, y1, x2, y2) {
    var dataImg = context.getImageData(x1, y1, x2, y2);

    var canvas2 = document.createElement("canvas");

    var context2 = canvas2.getContext("2d");

    var width = Math.abs(x1 - x2);

    var height = Math.abs(y1 - y2);

    canvas2.width = width;

    canvas2.height = height;
    console.log(width,height,x2,y2)

    context2.putImageData(dataImg, 0, 0, 0, 0, width, height);

    var res = canvas2.toDataURL('image/jpeg');

    return res;

  }
  function base64ToBlob(base64Data) {
    let arr = base64Data.split(','),
        fileType = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        l = bstr.length,
        u8Arr = new Uint8Array(l);

    while (l--) {
        u8Arr[l] = bstr.charCodeAt(l);
    }
    return new Blob([u8Arr], {
        type: fileType
    });
  }

  function upload() {
    let api = '/index.php'
    if(process.env.NODE_ENV === 'development'){
      api='https://pose.muselabs-eng.com/index.php'
    } else
      console.log('生产环境')
    // else if (process.env.NODE_ENV == 'production') {
    //     console.log('生产环境')
    // }
    countPoseRef.current = 9999
    console.log(picRef.current.getClientRects())
    console.log(picRef.current.getBoundingClientRect())
    html2canvas(document.body, {
      scale: 1,
      allowTaint: false,
      useCORS: true,
    }).then(function (canvas) {
      const base64 = getBase64Image(canvas.getContext("2d"), 0, 0, vWidth, vHeight)
      setBase64(base64)
      const dataImg = base64ToBlob(base64)
      //dataImg.src = canvas.toDataURL('image/png')
      let formData = new FormData()
      formData.append("file", dataImg,"1.jpg")
      setDraw(true)
      Axios({
        url:api,
        method: 'post',
        headers: {
          'Content-Type': 'multipart/form-data',
          // "Access-Control-Allow-Headers": "access-control-allow-origin, authority, content-type, version-info, X-Requested-With"
        },
        data:formData
      }).then(
        request => {
          console.log(request.data)
          if (request.data.code === 1) {
            popResult( request.data.data)
          } else {
            countPoseRef.current = 0
            setCountPose(0)
          }
        },
        error =>{
          console.log(error.data)
          countPoseRef.current = 0
          setCountPose(0)
        }
      )
    });
  }

  function popResult(url) {
    setUrl('https://'+window.location.host+url)
    if (timer.current != null) {
      clearTimeout(timer.current)
      timer.current=null
    }
    countDownRef.current=31
    timer.current = setInterval(() => {
      if (countDownRef.current > 0)
      {
        countDownRef.current--
        setCountDown(countDownRef.current)
      } else {
        clearTimeout(timer.current)
        timer.current = null
        closePop()
        }
    }
      , 1000);
    
  }

  function download() {
    setDraw(false)
    return
    console.log(picRef.current.getClientRects())
    console.log(picRef.current.getBoundingClientRect())
    html2canvas(document.body, {
      allowTaint: false,
      useCORS: true,
    }).then(function (canvas) {
      let width=1500
      let x1 = (canvas.width - width) / 2
      let x2 = (canvas.width - width) / 2 + width
      let y1 = 0
      let y2 = width/1300*1000

      const dataImg = new Image()
      dataImg.src = getBase64Image(canvas.getContext("2d"),x1,y1,x2,y2)
      //dataImg.src = canvas.toDataURL('image/png')
      const alink = document.createElement("a");
      alink.href = dataImg.src;
      alink.download = "testImg.jpg";
      alink.click();
    });
  }
  function closePop() {
    setUrl('')
    countDownRef.current = 0
    countPoseRef.current=0
    setCountPose(0)
  }

  let isDown = false
  /*
      ['left_shoulder','left_elbow'],
    ['left_elbow','left_wrist'],
  */
  function countPush(pose) {
    let left = angle(pose['left_elbow'], pose['left_wrist'], pose['left_shoulder'])
    let right = angle(pose['right_elbow'], pose['right_wrist'], pose['right_shoulder'])
    if (left === -1 && right === -1)
      return
    if (isDown) {
      if ((left > 150 && right > 150) ||
        (left > 150 && right === -1) ||
        (left === -1 && right > 150)) {
        console.log('count +1')
        isDown = false
        pushAmount.current = pushAmount.current + 1;
        if(pushAmount.current===5)
          download()
      }
    } else {
      if (left < 100 && right < 100) {
        isDown = true
      }
    }
  }

  //獲取pose特徵點
  const getPoseInfo = (pose) => {
    poseRef.current = pose
    if (timerCheckPose.current === null) {
      timerCheckPose.current = setInterval(() => {
        if (countPoseRef.current!==9999 &&countDownRef.current === 0) {
          console.log(countPoseRef.current)
          if ( poseRef.current!=null&& checkPose()) {
            countPoseRef.current++
            
            setCountPose(countPoseRef.current)
            if (countPoseRef.current === 5)
              setDraw(false)
            else if (countPoseRef.current > 5) {
              setCountPose(0)
              setDraw(false)
              upload()
            } else
              setDraw(true)
          }else {
            console.log('clear count',countPoseRef.current)
            setCountPose(0)
            countPoseRef.current=0
          } 
        }
      }
        , 1000);
    }
  }
  return (
    <div className="Home" ref={homeRef } style={{height:'100%'}}>
      <div id="capture">
        <div style={{ position: 'absolute',  left: '50%' }}>
          <CameraComponent width={vWidth} height={vHeight} draw={draw}
            getPoseFun={getPoseInfo}
          /></div>
        <img ref={ picRef} src={(process.env.PUBLIC_URL + '/res/back.png')} style={{
          position: 'absolute',
          top: '0',
          left: '0',
          width: '100%',
          height:'100%',
          zIndex:999
          // transform: 'translate(-50%,0)',
        }}
          alt="" />
      </div>
      <div style={{
        display:countPose>0 && countPose<5?'block':'none',
        left: '50%',
        top: '50%',
        fontSize: '100px',
        color:'blue',
        position: 'absolute',
        zIndex:1000
      }}><p>{ 5-countPose}</p></div>
      
      <button className={'bt small blue'}
        style={{
          bottom: '1%',
          display: 'none',
          left: '45%',
          transform: 'translate(-50%,0)',
          position: 'absolute',
          zIndex:1000
        }}
        onClick={upload}>上传</button>
            <button className={'bt small blue'}
        style={{
          bottom: '1%',
          display: 'none',
          left: '55%',
          transform: 'translate(-50%,0)',
          position: 'absolute',
          zIndex:1000
        }}
        onClick={download}>下载</button>
      <div style={{
          backgroundColor:'white',
          display: picUrl!==''?'block':'none',
          left: '50%',
          top: '50%',
          width: '50%',
          height:'40%',
          transform: 'translate(-50%,-50%)',
          position: 'absolute',
          zIndex:1000
      }}>
        <img style={ 
          {
            position:'absolute',
            left: '30%',
            top: '50%',
            width:'50%',
            transform: 'translate(-50%,-50%)',
          }
        }
          src={picBase64} alt=''></img>
        
        <div style={ 
          {
            position:'absolute',
            left: '60%',
            top: '50%',
          transform: 'translate(0,-50%)',
          }
        }>
          <QRCode
            value={picUrl}// 生成二维码的内容
            size={300} // 二维码的大小
            fgColor="#000000" // 二维码的颜色
            // imageSettings={{ // 中间有图片logo
            //   src: logo,
            //   height: 60,
            //   width: 60,
            //   excavate: true
            // }}
            />
        </div>
        <button className={'bt small blue'}
        style={{
          bottom: '1%',
          display: 'block',
          left: '50%',
          transform: 'translate(-50%,0)',
          position: 'absolute',
          zIndex:1000
        }}
          onClick={closePop}>關閉({countDown }s)</button>
      </div>
      
    </div>
  )
}


export default Home;